/**
 *
 *
 */

document.addEventListener("turbolinks:load", function () {
  if (document.getElementById("hands_on_main_view")) {
    modal_at_startup()
    hands_on_ux()
    clipboard_show_tooltip()
    menu_bar_collapse()
    imgZoom()
  }
  if (document.getElementById("js_hands_on_list_view")) {
    list_checkbox_filter()
  }
})

function menu_bar_collapse() {
  const toggleButton = document.getElementById("menu-collapse-toggle")
  const mySidenav = document.getElementById("menu-side")
  const main = document.getElementById("content-side")
  toggleButton.addEventListener("click", function () {
    mySidenav.classList.toggle("menu-side-closed")
    main.classList.toggle("menu-side-closed")
  })
}

function modal_at_startup() {
  let loginBtn = document.getElementById("js-hands-on-login-button")
  if (loginBtn && loginBtn.dataset.bsTarget == "#login-modal") {
    // 非ログインでログインモーダルを出すときは何もしない
    return
  }

  fetch(location.pathname + "/status", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json()
      } else if (res.status === 403) {
        return { exists: false }
      } else {
        throw new Error("Network response was not ok.")
      }
    })
    .then((data) => {
      if (data.exists == true) {
        // TODO コピペになっているのでリファクタリング
        let completeBtnEl = document.getElementById("js-hands-on-complete-button")
        let startBtnEl = document.getElementById("js-hands-on-start-button")

        startBtnEl.classList.add("d-none")
        completeBtnEl.classList.toggle("d-none")

        console.log("click js-hands-on-start-button")
        let infoEl = document.getElementById("js-hands-on-console-info")
        if (infoEl.classList.contains("opacity-25")) {
          infoEl.classList.remove("opacity-25")
        }

        document.querySelectorAll(".hands-on-secret-button").forEach(function (button) {
          button.disabled = false
        })

        // js-hands-on-console-button

        if (data.credential) {
          // console.log("complete", ev.detail[0].credential)
          const cred = data.credential
          if (cred) {
            document.getElementById("js-hands-on-username").value = cred.username
            document.getElementById("js-hands-on-password").value = cred.password
            let linkEl = document.getElementById("js-hands-on-signin-link")
            linkEl.value = cred.signin_link
            linkEl.disabled = false
          }
        }

        document.querySelectorAll(`.js-hands-on-step`).forEach((el) => {
          el.classList.remove("d-none")
        })

        document.getElementById("js-hands-on-active-indicator").classList.remove("d-none")

        let collapseHandsOnDescEle = document.getElementById("collapseHandsOnDesc")
        collapseHandsOnDescEle.classList.remove("show")

        document.getElementById("js-hands-on-stop-button").classList.remove("d-none")

        if (data.rest_of_seconds) {
          // document.getElementById("js-rest-of-hands-on-time").data.secondsLeft =
          //   data.rest_of_seconds = (data.rest_of_seconds / 60).toFixed(0)
          let $ = require("jquery")
          require("jquery-simple-timer")($)
          $("#js-rest-of-hands-on-time").startTimer()
        }
      } else if (data.exists == false && data.rest_of_seconds == 0) {
      }
    })
  let isRunning = document.getElementById("js-another-handson-running")
  if (isRunning) {
    console.log("another session is active")
    let modalEl = document.getElementById("js-alert-handson-modal")
    let modal = new bootstrap.Modal(modalEl)
    modal.show()
  }

  let formEl = document.getElementById("js-hands-on-alert-stop-form")
  if (formEl) {
    formEl.addEventListener("ajax:success", (ev) => {
      let modalEl = document.getElementById("js-alert-handson-modal")
      let modal = bootstrap.Modal.getInstance(modalEl)
      modal.hide()
      window.location.reload()
    })
  }

  let feedbackFormEl = document.getElementById("js-handson-feedback-modal-form")
  if (feedbackFormEl) {
    feedbackFormEl.addEventListener("ajax:success", (ev) => {
      ;["handson-good-feedback-modal", "handson-bad-feedback-modal"].forEach((id) => {
        let modalEl = document.getElementById(id)
        if (modalEl) {
          let modal = bootstrap.Modal.getInstance(modalEl)
          modal && modal.hide()
        }
      })
    })
  }
}

function hands_on_ux() {
  let startFormEl = document.getElementById("js-hands-on-start-form")
  if (!startFormEl) {
    return
  }

  startFormEl.addEventListener("ajax:success", (ev) => {
    // startBtnEl.innerHTML = `<div class="spinner-border text-info" role="status"><span class="visually-hidden">Loading...</span></div>`
    let completeBtnEl = document.getElementById("js-hands-on-complete-button")
    let startBtnEl = document.getElementById("js-hands-on-start-button")

    startBtnEl.classList.add("d-none")
    completeBtnEl.classList.toggle("d-none")

    console.log("click js-hands-on-start-button")
    let infoEl = document.getElementById("js-hands-on-console-info")
    if (infoEl.classList.contains("opacity-25")) {
      infoEl.classList.remove("opacity-25")
    }
    document.querySelectorAll(".hands-on-secret-button").forEach(function (button) {
      button.disabled = false
    })

    // js-hands-on-console-button

    if (ev.detail[0].status == "success") {
      console.log("complete", ev.detail[0].credential)
      const cred = ev.detail[0].credential
      if (cred) {
        document.getElementById("js-hands-on-username").value = cred.username
        document.getElementById("js-hands-on-password").value = cred.password
        let linkEl = document.getElementById("js-hands-on-signin-link")
        linkEl.value = cred.signin_link
        linkEl.disabled = false
      }
    }

    document.querySelectorAll(`.js-hands-on-step`).forEach((el) => {
      el.classList.remove("d-none")
    })

    document.getElementById("js-hands-on-active-indicator").classList.remove("d-none")

    let collapseHandsOnDescEle = document.getElementById("collapseHandsOnDesc")
    collapseHandsOnDescEle.classList.remove("show")

    document.getElementById("js-hands-on-stop-button").classList.remove("d-none")

    if (ev.detail[0].rest_of_seconds) {
      // document.getElementById("js-rest-of-hands-on-time").innerHTML =
      //   (ev.detail[0].rest_of_seconds / 60).toFixed(0) + "分"
      let $ = require("jquery")
      require("jquery-simple-timer")($)
      $("#js-rest-of-hands-on-time").startTimer()
    }
  })
  startFormEl.addEventListener("ajax:error", (ev) => {
    console.log("error", ev)
    if (ev.detail[0].type == "admin") {
      let modalEl = document.getElementById("confirm-admin-certification-modal")
      let modal = new bootstrap.Modal(modalEl)
      modal.show()
    }
  })

  let stopBtnForm = document.getElementById("js-hands-on-stop-form")
  let completeBtnForm = document.getElementById("js-hands-on-complete-form")
  let stopBtnEl = document.getElementById("js-hands-on-stop-button")
  let completeBtnEl = document.getElementById("js-hands-on-complete-button")
  let formArr = [stopBtnForm, completeBtnForm]
  formArr.forEach((el) => {
    el.addEventListener("ajax:success", (ev) => {
      stopBtnEl.classList.remove("d-none")
      completeBtnEl.classList.toggle("d-none")

      document.getElementById("js-hands-on-stop-button").classList.add("d-none")
      document.getElementById("js-hands-on-active-indicator").classList.add("d-none")

      let infoEl = document.getElementById("js-hands-on-console-info")
      if (!infoEl.classList.contains("opacity-25")) {
        infoEl.classList.add("opacity-25")
      }
      document.querySelectorAll(".hands-on-secret-button").forEach(function (button) {
        button.disabled = true
      })

      // js-hands-on-console-button
      ;["username", "password", "signin-link"].forEach((k, v) => {
        let ele = document.getElementById("js-hands-on-" + k)
        ele.value = ""
      })

      document.querySelectorAll(`.js-hands-on-step`).forEach((el) => {
        el.classList.add("d-none")
      })

      let collapseHandsOnDescEle = document.getElementById("collapseHandsOnDesc")
      collapseHandsOnDescEle.classList.add("show")

      document.getElementById("js-rest-of-hands-on-time").innerHTML = "-"
      //
    })
  })
}

function list_checkbox_filter() {
  const queryParam = new URLSearchParams(window.location.search)
  const filter = queryParam.get("filter")

  const vendorCheckboxes = document.querySelectorAll(".vendor")
  const levelCheckboxes = document.querySelectorAll(".level")
  const topicCheckboxes = document.querySelectorAll(".topic")

  const items = document.querySelectorAll(".hands-on-card")
  vendorCheckboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", filterItems)
  })
  levelCheckboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", filterItems)
  })
  topicCheckboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", filterItems)
  })
  function filterItems() {
    const selectedVendors = Array.from(vendorCheckboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)
    const selectedLevels = Array.from(levelCheckboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)
    const selectedTopics = Array.from(topicCheckboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)

    items.forEach(function (item) {
      const itemVendor = item.getAttribute("data-vendor")
      const itemLevel = item.getAttribute("data-level")
      const itemTopic = item.getAttribute("data-topic").split("・")

      const showByVendor = selectedVendors.length === 0 || selectedVendors.includes(itemVendor)
      const showByLevel = selectedLevels.length === 0 || selectedLevels.includes(itemLevel)
      const showByTopic = selectedTopics.length === 0 || selectedTopics.some((topic) => itemTopic.includes(topic)) // OR検索（選択したトピックが1つでも含まれていれば表示）

      if (showByVendor && showByLevel && showByTopic) {
        item.style.display = "block"
      } else {
        item.style.display = "none"
      }
    })
  }
  if (filter) {
    vendorCheckboxes.forEach(function (checkbox) {
      if (checkbox.value === filter) {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }
    })
    items.forEach(function (item) {
      const itemVendor = item.getAttribute("data-vendor")
      if (filter === itemVendor) {
        item.style.display = "block"
      } else {
        item.style.display = "none"
      }
    })
  }

  const filterTitles = document.querySelectorAll(".filter_title")

  filterTitles.forEach(function (filterTitle) {
    filterTitle.addEventListener("click", function (event) {
      filterTitle.classList.toggle("t-active")
      const parentSegmentation = filterTitle.closest(".filter_segmentation")
      const formChecks = parentSegmentation.querySelectorAll(".form-check")
      formChecks.forEach(function (formCheck) {
        if (formCheck.style.display === "none") {
          formCheck.style.display = "block"
        } else {
          formCheck.style.display = "none"
        }
      })
    })
  })
}

function clipboard_show_tooltip() {
  // Create tooltip element
  var tooltip = document.createElement("div")
  tooltip.className = "clipboardtooltip"
  tooltip.style.display = "none"
  tooltip.style.position = "fixed"
  tooltip.style.padding = "8px 12px"
  tooltip.style.backgroundColor = "#131313"
  tooltip.style.color = "#fff"
  tooltip.style.fontSize = "12px"
  tooltip.style.lineHeight = "130%"
  tooltip.style.borderRadius = "6px"
  tooltip.style.zIndex = "100000"
  tooltip.textContent = "コピーしました"
  tooltip.style.opacity = "1"
  tooltip.style.transition = "opacity 1.1s ease-out, transform 1.1s ease-out" // Add transition effect
  document.body.appendChild(tooltip)

  var buttons = document.querySelectorAll(".hands-on-secret-button")
  buttons.forEach(function (button) {
    button.addEventListener("click", function (e) {
      // Determine which element to get the position from
      var positionElement = e.target
      if (e.target.classList.contains("material-icons")) {
        positionElement = e.target.parentNode
      }

      // Get the position of the chosen element
      var rect = positionElement.getBoundingClientRect()

      // Show the tooltip
      tooltip.style.display = "block"
      // Position the tooltip relative to the chosen element
      tooltip.style.left = `calc(${rect.left + rect.width / 2}px - 26px)`
      tooltip.style.top = `calc(${rect.top + window.scrollY}px - 35px)`
      // Apply initial transform state
      tooltip.style.transform = "translateY(0px)"
      // Slight delay to ensure the transition starts
      // Hide the tooltip after 2 seconds and reset opacity
      setTimeout(function () {
        tooltip.style.display = "none"
        tooltip.style.opacity = "1" // Reset opacity for next time tooltip is shown
      }, 1100)
    })
  })
}

function imgZoom() {
  console.log("imgZoom")
  const parentDivs = document.querySelectorAll(".hands-on-step-item-content")

  parentDivs.forEach(function (parentDiv) {
    const imageArea = parentDiv.querySelector(".hand-on-image-container")
    const imageElement = parentDiv.querySelector(".hands-on-step-item-content-image")
    if (imageElement) {
      imageArea.addEventListener("click", function () {
        const imgZoomModal = document.querySelector(".img-zoom-modal")

        const zoomedImage = imgZoomModal.querySelector(".img-zoom-content")

        zoomedImage.src = imageElement.src
        imgZoomModal.style.display = "flex"
      })
    }
  })

  const imgZoomCloses = document.querySelectorAll(".img-zoom-close")

  imgZoomCloses.forEach(function (imgZoomClose) {
    imgZoomClose.addEventListener("click", closeImgZoomModal)
  })

  // Attach keydown event listener to window for 'Esc' key
  window.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      closeImgZoomModal()
    }
  })

  window.addEventListener("click", function (event) {
    const imgZoomModals = document.querySelectorAll(".img-zoom-modal")
    imgZoomModals.forEach(function (modal) {
      if (event.target === modal) {
        modal.style.display = "none"
      }
    })
  })
}

function closeImgZoomModal() {
  const imgZoomModals = document.querySelectorAll(".img-zoom-modal")
  imgZoomModals.forEach(function (modal) {
    modal.style.display = "none"
  })
}
