//------------------------------------------------------------------------------------
// Arrayとかあればここに。
//------------------------------------------------------------------------------------
var height_of_passing_note_section = []

//------------------------------------------------------------------------------------
// いざfunctionを呼ぶ。
//------------------------------------------------------------------------------------
document.addEventListener("turbolinks:load", function () {
  if (
    location.hostname.includes("cloudpanda") &&
    !window.location.pathname.match(/\/hands_ons\//)
  ) {
    // ToBでの共通処理もここに
    // 右クリック禁止
    document.oncontextmenu = function () {
      return false
    }
    let customerBody = document.getElementsByClassName("customer-body")
    if (customerBody && customerBody[0]) {
      customerBody[0].style.userSelect = "none"
    }
  }

  show_menu_dropdowns()
  // モダル関連
  feedback_modal()
  conversion_modal()
  signup_for_purchase_modal()
  login_modal()
  password_reset_modal()

  // LPで使う
  if (document.getElementById("lp")) {
    lp_page_detection()
  }

  // 合格記で使う
  if (document.getElementById("passing_notes")) {
    passing_notes_init()
    postnewNote()
    drawerComponent()
  }

  // 設定のページ
  if (document.getElementById("setting-main-view")) {
    // confirm_email_change_modal()
    // change_setting_content()
    // stripe = stripe_init()
  }
  // マイページ
  if (document.getElementById("mypage-main-view")) {
    acquire_certification_modal()
  }
})

//------------------------------------------------------------------------------------
// 各々のfunction
//------------------------------------------------------------------------------------

function flashAlert(message, type) {
  var alertPlaceholder = document.getElementById("flash-alert-js")
  var wrapper = document.createElement("div")
  const icon_class = type == "danger" ? "fa-circle-exclamation" : "fa-info-circle"
  wrapper.innerHTML = `<div class="d-flex justify-content-between alert alert-${type} " role="alert">
      <div class="d-flex justify-content-start align-items-center" style="gap:12px;font-weight:700;">
        <i class="fas ${icon_class} icon"></i>
        <div class="message">${message} </div>
      </div>
      <span class="material-icons-outlined close" data-bs-dismiss="alert" aria-label="Close">close</span>
    </div>`

  alertPlaceholder.append(wrapper)
}

function feedback_modal() {
  document.querySelectorAll(".question-feedback-modal-form").forEach((element) => {
    element.addEventListener("ajax:success", function (event) {
      const modal = event.currentTarget.closest(".feedback-modal")
      bootstrap.Modal.getInstance(modal).hide()
      flashAlert("フィードバックにご協力頂き、ありがとうございます。", "info")
    })
  })

  // 以下ご意見箱
  document.querySelectorAll(".contact-modal-form").forEach((element) => {
    element.addEventListener("ajax:success", function (event) {
      const modal = event.currentTarget.closest(".feedback-modal")

      bootstrap.Modal.getInstance(modal).hide()
      // flashAlert(
      //   "ご意見・お問い合わせありがとうございます。メールにて順次返信いたします。",
      //   "info"
      // );
    })

    element.addEventListener("ajax:error", (event) => {
      const msgEle = document.querySelector("#contact-error-message")
      if (event.detail[0].messages) {
        msgEle.innerHTML = event.detail[0].messages.map((s) => `<li>${s}</li>`).join("")
        const certEle = element.querySelector(".input-and-select > input")
        certEle.classList.remove("added-alert")
        if (event.detail[0].errors["category"]) {
          certEle.classList.add("added-alert")
        }
        const numberEle = element.querySelector("#contact_email")
        numberEle.classList.remove("added-alert")
        if (event.detail[0].errors["email"]) {
          numberEle.classList.add("added-alert")
        }

        msgEle.classList.add("visible")
      } else {
        document.querySelector("#contact-error-message").innerHTML = "登録に失敗しました。"
      }
    })
  })

  // オレオレselect(customized-select)で外をクリックしたときに閉じる
  // document
  //   .querySelectorAll(".contact-modal-form > .modal-body")
  //   .forEach((el) => {
  //     el.addEventListener("click", function (e) {
  //       const modal = e.currentTarget.closest(".feedback-modal");
  //       const select = modal.querySelector(".customized-select");
  //       if (select.contains(e.target)) {
  //         return;
  //       }
  //       select.classList.remove("dropped-down");
  //     });
  //   });

  // document.querySelectorAll(".feedback-modal").forEach((element) => {
  //   document.onkeydown = (e) => {
  //     if (!element.parentNode.querySelector(".feedback-modal.show")) {
  //       return; // つねに発火してうざいので modalが見えてなかったらearly return
  //     }
  //     e = e || window.event;
  //     var myModalEl = document.getElementById("contact-modal");
  //     var modal = bootstrap.Modal.getInstance(myModalEl);
  //     if (e.key === "Backspace" && modal) {
  //       // backspace でモーダルを閉じる。bootstrapではEscでは閉じるけど、拡張がないのでベタに書くしかない
  //       modal.hide();
  //     }
  //   };
  // });
}

function conversion_modal() {
  //課金or会員登録してくださいモーダル
  const modal = document.querySelector("#modal-pay")
  let isModalVisible = false
  $(".locked").on("click", (e) => {
    e.preventDefault()
    $(modal).addClass("visible")
    isModalVisible = true
  })
  $("#modal-pay").on("click", (e) => {
    if (isModalVisible && e.target === e.currentTarget) {
      $(modal).removeClass("visible")
      isModalVisible = false
    }
  })
  $(".btn-modal-close").on("click", (e) => {
    $(modal).removeClass("visible")
    isModalVisible = false
  })
}

function signup_for_purchase_modal() {
  const modal = document.querySelector("#signup-payment-method-modal")
  if (!modal) {
    return
  }
  modal.querySelectorAll("#new_user").forEach((el) => {
    el.addEventListener("ajax:success", (event) => {
      // close modal
      Bootstrap.Modal.getInstance(modal).hide()
    })
    el.addEventListener("ajax:error", (event) => {
      console.log(JSON.stringify(event.detail))
      if (event.detail[0].messages) {
        document.querySelector("#signup-message").innerHTML = event.detail[0].messages.join("<br>")
      } else {
        document.querySelector("#signup-message").innerHTML = "会員登録に失敗しました。"
      }
    })
  })
}

function login_modal() {
  // const modal = document.querySelector("#modal-login");
  // const modalContent = document.querySelector("#modal-login .modal-card");
  // let visible = false;
  // document.querySelectorAll(".login-modal-link").forEach(function (el) {
  //   el.addEventListener("click", function (e) {
  //     e.preventDefault();
  //     document.querySelector("#modal-signup").classList.remove("visible");
  //     document
  //       .querySelector("#modal-password-reset")
  //       .classList.remove("visible");

  //     $(modal).addClass("visible");
  //     visible = true;
  //   });
  // });

  // $("#modal-login").on("click", (e) => {
  //   if (visible && !modalContent.contains(e.target)) {
  //     $(modal).removeClass("visible");
  //     visible = false;
  //   }
  // });
  // $(".btn-modal-close").on("click", (e) => {
  //   $(modal).removeClass("visible");
  //   visible = false;
  // });

  // ログインモーダルとページのほう
  let logins = [document.querySelector("#new_user"), document.querySelector("#login-modal-form")]

  logins.forEach(function (element) {
    if (!element) {
      return
    }
    element.addEventListener("ajax:error", function (event) {
      console.log(JSON.stringify(event.detail))
      let msgEle = document.querySelector("#login-message")
      if (!msgEle) {
        return
      }
      if (event.detail[0].error) {
        msgEle.classList.add("visible")
        msgEle.innerHTML = event.detail[0].error // 他のと形式違うがこれはdeviseのをそのまま使ってるから。see SessionController
      } else {
        msgEle.classList.add("visible")
        msgEle.innerHTML = "ログインに失敗しました。"
      }
    })
  })
}

function password_reset_modal() {
  // const modal = document.querySelector("#modal-password-reset");
  // const modalContent = document.querySelector(
  //   "#modal-password-reset .modal-card"
  // );
  // let visible = false;
  // document
  //   .querySelectorAll(".password-reset-modal-link")
  //   .forEach(function (el) {
  //     el.addEventListener("click", function (e) {
  //       e.preventDefault();
  //       const signupEl = document.querySelector("#modal-signup");
  //       signupEl && signupEl.classList.remove("visible");
  //       const loginEl = document.querySelector("#modal-login");
  //       loginEl && loginEl.classList.remove("visible");

  //       $(modal).addClass("visible");
  //       visible = true;
  //     });
  //   });

  // $("#modal-password-reset").on("click", (e) => {
  //   if (visible && !modalContent.contains(e.target)) {
  //     $(modal).removeClass("visible");
  //     visible = false;
  //   }
  // });
  // $(".btn-modal-close").on("click", (e) => {
  //   $(modal).removeClass("visible");
  //   visible = false;
  // });

  document.querySelectorAll("#password-reset-modal-form").forEach((element) => {
    element.addEventListener("ajax:error", (event) => {
      console.log(JSON.stringify(event.detail))
      let msgEle = document.querySelector("#password-reset-message")
      if (!msgEle) {
        return
      }
      if (event.detail[0].messages) {
        msgEle.classList.add("visible")
        msgEle.innerHTML = event.detail[0].messages.join("<br>")
      } else {
        msgEle.classList.add("visible")
        msgEle.innerHTML = "パスワードリセットに失敗しました。"
      }
    })
  })
}

function acquire_certification_modal() {
  document.querySelectorAll("#acquire-certification-modal").forEach((element) => {
    const msgEle = document.querySelector("#acquire-certification-message")
    element.addEventListener("ajax:error", (event) => {
      if (event.detail[0].messages) {
        // certification: ["を入力してください"]
        // credential_number: ["を入力してください"]
        msgEle.innerHTML = event.detail[0].messages.map((s) => `<li>${s}</li>`).join("")
        const certEle = element.querySelector("#acquired_certification_certification_id")
        certEle.classList.remove("alert1")
        if (event.detail[0].errors["certification"]) {
          certEle.classList.add("alert1")
        }
        const numberEle = element.querySelector("#acquired_certification_credential_number")
        numberEle.classList.remove("alert1")
        if (event.detail[0].errors["credential_number"]) {
          numberEle.classList.add("alert1")
        }

        msgEle.classList.add("visible")
      } else {
        msgEle.innerHTML = "登録に失敗しました。"
        msgEle.classList.add("visible")
      }
    })
  })
  var myModalEl = document.getElementById("acquire-certification-modal")
  myModalEl.addEventListener("hidden.bs.modal", function (event) {
    document.querySelector("#acquire-certification-message").classList.remove("visible")
    document.querySelector("#acquired_certification_certification_id").classList.remove("alert1")
    document.querySelector("#acquired_certification_credential_number").classList.remove("alert1")
  })
  // document
  //   .querySelectorAll(".acquire-certification-modal")
  //   .forEach((element) => {
  //     document.onkeydown = (e) => {
  //       e = e || window.event;
  //       if (e.keyCode == "8") {
  //         // backspace でモーダルを閉じる。bootstrapにオプションがないのでベタに書くしかない
  //         console.log("backspace");
  //         var modal = bootstrap.Modal.getInstance(myModalEl);
  //         modal.hide();
  //       }
  //     };
  //   });
}

function postnewNote() {
  $(".post_new_passing_note").on("click", (e) => {
    e.preventDefault()
    $("#post_new_note").addClass("visible")
  })
  $(".modal_closer_class").on("click", (e) => {
    $("#post_new_note").removeClass("visible")
  })
}

function drawerComponent() {
  $(".trigger_drawer").click(function () {
    $("#passing_note_drawer").addClass("visible")
  })
  $(".modal_closer_class").on("click", (e) => {
    $("#passing_note_drawer").removeClass("visible")
  })
}

function passing_notes_init() {
  $("#passing_notes_home .category_name a").first().addClass("active")
  $(".category_name").click(function () {
    var index = $(this).text()
    console.log(index)
    $("html, body").animate(
      {
        scrollTop: $("#scroll-to-" + index).offset().top - (72 + 16),
      },
      100
    )
  })

  var $navigationLinks = $("#navigation > ul > li > a")
  var $sections = $($(".js_height_calc").get().reverse())
  var sectionIdTonavigationLink = {}
  $sections.each(function () {
    var id = $(this).attr("id")
    sectionIdTonavigationLink[id] = $("#navigation > ul > li > a[href=\\#" + id + "]")
  })

  function highlightNavigation() {
    var scrollPosition = $(window).scrollTop()
    $sections.each(function () {
      var currentSection = $(this)
      var sectionTop = currentSection.offset().top - (72 + 16)
      if (scrollPosition >= sectionTop) {
        var id = currentSection.attr("id")
        var $navigationLink = sectionIdTonavigationLink[id]
        if (!$navigationLink.hasClass("active")) {
          $navigationLinks.removeClass("active")
          $navigationLink.addClass("active")
        }
        return false
      }
    })
  }

  $(window).scroll(highlightNavigation)
}

function lp_page_detection() {
  document.getElementById("header").classList.add("lp_ver")

  $(".tab").click(function () {
    $(".tab").removeClass("active")
    $(this).addClass("active")
    var store_number = $(this).index()
    $(".content_ooo").removeClass("active")
    $(".content_ooo").eq(store_number).addClass("active")
  })
}

function hide_menu_dropdown() {
  var Scrim = document.querySelectorAll(".custom_scrim")
  var Amount_of_Scrim = Scrim.length
  for (var i = Amount_of_Scrim - 1; i >= 0; i--) {
    Scrim[i].addEventListener("mouseenter", (event) => {
      console.log(".custom_scrim!?")
      document.querySelectorAll(".parent > .dropdown-toggle.show").forEach((el, i) => {
        setTimeout(function test() {
          console.log("hide!!!", el)

          const dropdown = new bootstrap.Dropdown(el)
          dropdown.hide()

          el.closest(".parent")
            .querySelectorAll(".custom_scrim")
            .forEach((scEl, i) => {
              console.log("hide sc", scEl)
              scEl.style.display = "none"
            })
        }, 300)
      })
    })
  }
}

function show_menu_dropdowns() {
  // ドロップダウンの表示/非表示(.showの有無で判定)の際にグレーアウト or グレーアウト解除する
  document.querySelectorAll(".parent > .dropdown-toggle").forEach((el, i) => {
    const observer = new MutationObserver(function (records) {
      /** DOMの変化が起こった時の処理 */
      if (el.classList.contains("show")) {
        document.querySelectorAll(".custom_scrim")[i].style.display = "block"
      } else {
        document.querySelectorAll(".custom_scrim")[i].style.display = "none"
      }
    })
    const config = {
      attributes: true,
      attributeFilter: ["class"],
    }
    observer.observe(el, config)
  })

  document.querySelectorAll(".mobile-notification-close").forEach((el) => {
    el.addEventListener("click", () => {
      document.getElementById("navbarDropdownNotify").click()
    })
  })

  // bootstrapのドロップダウン開塀ロジックを使用するため、以下は不要

  // ドロップダウンがクリックしたときにグレーアウト(scrim on)
  // 同時に他のドロップダウンを閉じる
  // document.querySelectorAll(".parent > .dropdown-toggle").forEach((el, i) => {
  //   el.addEventListener("click", () => {
  //     // 他のドロップダウンとscrimを閉じる。今から開くやつはnot(show)だよね？
  //     // TODO 他のドロップダウンを閉じる処理をまとめる
  //     // TODO グローバルメニューにホバーしたときに閉じる（今
  //     document
  //       .querySelectorAll(".parent > .dropdown-toggle.show")
  //       .forEach((drEl, i) => {
  //         // console.log("hide", drEl);
  //         const dropdown = new bootstrap.Dropdown(drEl);
  //         dropdown.hide();
  //         drEl
  //           .closest(".parent")
  //           .querySelectorAll(".custom_scrim")
  //           .forEach((scEl, i) => {
  //             scEl.style.display = "none";
  //           });
  //       });

  //     const dropdown = new bootstrap.Dropdown(el);
  //     dropdown.show();

  //     el.closest(".parent")
  //       .querySelectorAll(".custom_scrim")
  //       .forEach((scEl, i) => {
  //         //          console.log("show sc", scEl);
  //         scEl.style.display = "block";
  //       });
  //   });
  // });

  // scrimにホバーしたとき ドロップダウンを閉じてグレーアウト解除
  // scrimにホバーしたとき閉じるって動作じゃないかも?
  // document.querySelectorAll(".custom_scrim").forEach((el, i) => {
  //   el.addEventListener("click", (event) => {
  //     const that = event.target;
  //     setTimeout(function test() {
  //       // ちょっと遅延があったほうが自然
  //       //          console.log("hide sc", that);

  //       that
  //         .closest(".parent")
  //         .querySelectorAll(".dropdown-toggle")
  //         .forEach((el, i) => {
  //           const dropdown = new bootstrap.Dropdown(el);
  //           dropdown.hide();
  //         });
  //       // that.style.display = "none";
  //     }, 300);
  //   });
  // });
}

// 同じ関数たちがbizにもあるので注意
// function more_notifications_init() {
//   document.querySelectorAll(".dropdown-menu .more-notification").forEach((clickEl, i) => {
//     let dropdownEl = document.querySelector("#navbarDropdownNotify");
//     clickEl.addEventListener("click", (ev) => {
//       let cnt = 5;
//     document.querySelectorAll("li.item-wrapper").forEach((item, i) => {
//       if (item.classList.contains("hidden") && cnt > 0) {
//         item.classList.remove("hidden");
//         cnt--;
//       }
//     });
//       notification_click(dropdownEl);
//     });
//   });
// }

// function notification_click(dropdownEl) {

//     setTimeout(() => {
//       let badgeEl = dropdownEl.querySelector(
//         "#navbarDropdownNotify .notification-badge"
//       );
//       const notificationIds = Array.from(document.querySelectorAll(
//         ".notification-menu .item-wrapper.unread:not(.hidden)"
//         )).map((el) => el.dataset.id);
//       console.log("notification => notificationIds", notificationIds);
//       if (notificationIds.length > 0) {
//         $.ajax({
//           url: "/users/notices",
//           type: "DELETE",
//           data: {
//             ids: notificationIds,
//           },
//           success: (data) => {
//             console.log("success", data);
//             if (data.unreads  != null && data.unreads > 0) {
//               badgeEl.innerHTML = data.unreads;
//             } else if (data.unreads != null && data.unreads == 0) {
//               badgeEl.innerHTML = "";
//             }
//           },
//           error: (data) => {
//             console.log("error", data);
//           },
//         });
//       }
//     }, 500); // イランきがする
//   }

// function clear_notifications() {
//   // 通知を既読にする
//   let dropdownEl = document.querySelector("#navbarDropdownNotify");
//   if (!dropdownEl) {
//     return;
//   }
//   // FIXME 同じ画面で開いて閉じて開いてとかすると無限に消えるバグが有る
//   // あとバッジの数を減らしたほうがいい
//   dropdownEl.addEventListener("click", () => {
//     notification_click(dropdownEl);
//   });
// }

function update_last_seen(location, current) {
  if (location && location.href && location.href.includes("exam_mode")) {
    return
  }
  const url = location.href.replace("#", "") + "/last_seen"
  $.ajax({
    type: "POST",
    data: { qid: current },
    url: url,
    success: function () {
      console.log(`success last_seen ${url} ${current}`)
    },
  })
}
