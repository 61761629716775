// この関数はbizでも使われているので注意!!!
document.addEventListener("turbolinks:load", function () {
  $(".certification-menu .vender-item .vender-tab").on("click", (e) => {
    const venderName = e.currentTarget.dataset.venderName
    $(".vender-item .vender-tab").removeClass("active")
    $(`.vender-item .vender-tab[data-vender-name="${venderName}"]`).addClass("active")

    $(".vender-certifications-list").removeClass("active")
    $(`.vender-certifications-list[data-vender-name="${venderName}"]`).addClass("active")
  })

  $(".mobile-menu .mobile-vender-dropdown").on("click", (e) => {
    console.log("mobile click")
    const venderName = e.currentTarget.dataset.venderName
    $(`.mobile-menu .mobile-vender-dropdown[data-vender-name="${venderName}"]`).toggleClass(
      "active"
    )
    $(`.mobile-menu .vender-certifications-list[data-vender-name="${venderName}"]`).toggleClass(
      "active"
    )
  })

  // グローバルナビゲーション
  // show_menu_dropdowns();
  more_notifications_init()
  clear_notifications()
  mobileScrimClear()
  lockPageScroll()

  // バナー関連
  adjust_banner_filler_height()

  // マイページ
  mypageEmptyState()
})
function lockPageScroll() {
  var dropdown = document.getElementById("navbarDropdownCerts")

  if (dropdown) {
    dropdown.addEventListener("click", function () {
      if (!document.body.classList.contains("lockScroll")) {
        document.body.classList.add("lockScroll")
        document.querySelectorAll(".scrimOnHeader").forEach((element) => {
          element.classList.add("lockScroll")
        })
      }
    })

    document.querySelectorAll(".custom_scrim").forEach((element) => {
      element.addEventListener("click", function () {
        document.body.classList.remove("lockScroll")
        document.querySelectorAll(".scrimOnHeader").forEach((scrimElement) => {
          scrimElement.classList.remove("lockScroll")
        })
      })
    })

    document.querySelectorAll(".scrimOnHeader").forEach((element) => {
      element.addEventListener("click", function () {
        document.body.classList.remove("lockScroll")
        document.querySelectorAll(".scrimOnHeader").forEach((scrimElement) => {
          scrimElement.classList.remove("lockScroll")
        })
      })
    })
  }
}

function more_notifications_init() {
  document.querySelectorAll(".dropdown-menu .more-notification").forEach((clickEl, i) => {
    let dropdownEl = document.querySelector("#navbarDropdownNotify")
    clickEl.addEventListener("click", (ev) => {
      let cnt = 5
      document.querySelectorAll("li.item-wrapper.hidden").forEach((item, i) => {
        if (item.classList.contains("hidden") && cnt > 0) {
          item.classList.remove("hidden")
          cnt--
        }
      })
      notification_click(dropdownEl)

      // 隠れた通知(未読）がまだあるか
      const unreadCount = document.querySelectorAll("li.item-wrapper.hidden").length
      if (unreadCount == 0) {
        let el = document.querySelector(".dropdown-menu .more-notification-wrapper")
        el.classList.add("hidden")
      }
    })
  })
}

// 見えてる通知でunreadなもの全部既読にする
function notification_click(dropdownEl) {
  setTimeout(() => {
    let badgeEl = dropdownEl.querySelector("#navbarDropdownNotify .notification-badge")
    const notificationIds = Array.from(
      document.querySelectorAll(".notification-menu .item-wrapper.unread:not(.hidden)")
    ).map((el) => el.dataset.id)
    console.log("notification => notificationIds", notificationIds)
    if (notificationIds.length > 0) {
      $.ajax({
        url: "/users/notices",
        type: "DELETE",
        data: {
          ids: notificationIds,
        },
        success: (data) => {
          console.log("success", data)
          if (data.unreads != null && data.unreads > 0) {
            badgeEl.innerHTML = data.unreads
          } else if (data.unreads != null && data.unreads == 0) {
            badgeEl.innerHTML = ""
          }
          document
            .querySelectorAll(".notification-menu .item-wrapper.unread:not(.hidden)")
            .forEach((el) => {
              if (notificationIds.includes(el.dataset.id)) {
                el.classList.remove("unread")
                el.classList.add("read")
              }
            })
        },
        error: (data) => {
          console.log("error", data)
        },
      })
    }
  }, 1000) // 既読をじわっと消すために必要
}

function clear_notifications() {
  let dropdownEl = document.querySelector("#navbarDropdownNotify")
  let mobNotificationClose = document.querySelector("#mobile-notification-close")

  // Click handler for dropdownEl
  const handleDropdownClick = () => {
    // 通知を既読にする (Mark notifications as read)
    notification_click(dropdownEl)
    var hoverableMenuElements = document.querySelectorAll(".mobile_HM")

    hoverableMenuElements.forEach(function (element) {
      if (!element.classList.contains("hideHM")) {
        element.classList.add("hideHM")
      }
    })
  }

  // Click handler for mobNotificationClose

  const handleMobNotificationCloseClick = () => {
    var hoverableMenuElements = document.querySelectorAll(".mobile_HM")
    hoverableMenuElements.forEach(function (menuElement) {
      setTimeout(function () {
        menuElement.classList.remove("hideHM")
        console.log("hshshsh")
      }, 200) // 200ms delay
    })
  }

  if (dropdownEl) {
    dropdownEl.addEventListener("click", handleDropdownClick)
  }

  if (mobNotificationClose) {
    mobNotificationClose.addEventListener("click", handleMobNotificationCloseClick)
  }
}

// window resize の旅にバナーの高さを調整
function adjust_banner_filler_height() {
  if ($("#flash_alert")) {
    adjustFillerHeight()
    window.addEventListener("resize", adjustFillerHeight)
  } else {
    return
  }
}

function getOffset(el) {
  const rect = el.getBoundingClientRect()
  return {
    height: rect.height,
  }
}

function adjustFillerHeight() {
  var elem = document.getElementById("flash_alert")
  var header = document.getElementById("header")
  var filler = document.getElementById("banner-filler")
  var flastAlert = document.getElementById("flash_alert")
  if (elem) {
    filler.setAttribute("style", "height:" + getOffset(elem).height + "px;")
    flastAlert.setAttribute("style", "top:" + getOffset(header).height + "px;")
  }
}

//モバイル版スクリムクリックでハンバーガーメニューを再度表示
function mobileScrimClear() {
  var scrimElements = document.querySelectorAll(".custom_scrim")
  var mobileNotificatioButton = document.querySelectorAll(".mobile-notification-close")

  scrimElements.forEach(function (scrim) {
    scrim.addEventListener("click", function () {
      var hoverableMenuElements = document.querySelectorAll(".mobile_HM")
      hoverableMenuElements.forEach(function (menuElement) {
        menuElement.classList.remove("hideHM")
      })
    })
  })

  mobileNotificatioButton.forEach(function (s) {
    s.addEventListener("click", function () {
      var hoverableMenuElements = document.querySelectorAll(".mobile_HM")
      hoverableMenuElements.forEach(function (menuElement) {
        menuElement.classList.remove("hideHM")
      })
    })
  })
}

function mypageEmptyState() {
  document.querySelectorAll(".clicktoreveal").forEach(function (div) {
    div.addEventListener("click", function () {
      // Find the nearest parent with the class 'flex-grid-2-cols'
      let flexGrid = div.closest(".flex-grid-2-cols")
      if (flexGrid) {
        // Toggle the 'show-all' class on this flex-grid
        flexGrid.classList.toggle("show-all")
      }
    })
  })
  document.querySelectorAll(".clicktominimize").forEach(function (div) {
    div.addEventListener("click", function () {
      // Find the nearest parent with the class 'flex-grid-2-cols'
      let flexGrid = div.closest(".flex-grid-2-cols")
      if (flexGrid) {
        // Toggle the 'show-all' class on this flex-grid
        flexGrid.classList.toggle("show-all")
      }
    })
  })
}
